import cx from 'classnames';
import { Card } from '@mantine/core';
import { CaretDown, CaretUp } from 'phosphor-react';

import { Text } from 'andromeda';
import { colorPallet } from '@/constants/colors';
import StyledTooltip from '@/components/utils/styledTooltip';
import { driverBreakdown } from '../SegmentsAnalysisPage/driverBreakdown';

interface SalesConstructCardProps {
    name: string;
    title: string | number;
    subtitle: string;
    change: number;
    isActive: boolean;
    onHover: () => void;
    onLeave: () => void;
    onClick: () => void;
    hoverColor?: string;
}

export const SalesConstructCard = ({
    name,
    title,
    subtitle = '',
    change,
    isActive,
    onHover,
    onLeave,
    onClick,
    hoverColor,
}: SalesConstructCardProps) => (
    <Card
        padding="0"
        radius="md"
        withBorder
        className="min-h-[136px] min-w-60 cursor-pointer"
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        onClick={onClick}
        styles={() => ({
            root: {
                borderColor: isActive ? hoverColor : '#E6E6E6',
            },
        })}
    >
        <div className="flex flex-col p-[36px]">
            <div className="flex flex-col">
                <StyledTooltip label={driverBreakdown['Sales Construct']?.[name]}>
                    <div className="text-[16px] font-medium text-neutral-300">{name}</div>
                </StyledTooltip>
                <div className="flex min-w-max items-center">
                    <div className="text-3xl font-bold !text-neutral-300">{title}</div>
                    <div
                        className={cx('rounded-full px-2 py-1 ml-2 flex', {
                            'bg-[#dff4e5]': change > 0,
                            'bg-[#f3e1e2]': change < 0,
                        })}
                    >
                        {change > 0 ? (
                            <CaretUp size={18} color={colorPallet.emerald.dark} weight="fill" />
                        ) : (
                            <CaretDown size={18} color={colorPallet.coral.dark} weight="fill" />
                        )}
                        <Text
                            type="14Reg"
                            className={`!font-bold ${change > 0 ? 'text-emerald-dark' : 'text-coral-dark'}`}
                        >
                            {subtitle}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    </Card>
);
