import { Description } from '../useGetInsights';

interface SentenceProps {
    segments: Description[];
}

export const StyledSentence: React.FC<SentenceProps> = ({ segments }) => (
    <>
        {segments.map((segment, index) => {
            const key = index;
            return (
                <span key={key} className={segment?.style === 'regular' ? '' : 'font-bold'}>
                    {segment?.value}{' '}
                </span>
            );
        })}
    </>
);
