import { ChartDataset, ScriptableLineSegmentContext } from 'chart.js';

import colors, { colorPallet } from '@/constants/colors';
import { fusionMoment } from '@/utils/dateHelpers';
import { ChartData, OverviewData } from '../../useGetInsights';

type OverviewChartData = OverviewData['revenue']['current']['datasets'];

export type RevenueChartDatasetType = ChartDataset<'line'> & {
    tooltipBackgroundColor: string;
};

const formatChartData = (chart_data: ChartData, color: string, label: string) => ({
    name: label,
    color,
    values: chart_data?.x
        .map((date, index) => ({
            x: date,
            y: chart_data?.y[index],
        }))
        .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()),
});

const getColorForDataset = (index: number) => {
    switch (index) {
        case 0:
            return colors.orange;
        case 1:
            return colors.magenta;
        case 2:
            return colors.darkGreen;
        default:
            return colors.boldBlue;
    }
};

interface DottedLineParams {
    chartData: ChartData;
    context: ScriptableLineSegmentContext;
    targetDate: string;
}

const createDottedLine = ({ chartData, context, targetDate }: DottedLineParams) => {
    const currentDateString = chartData.x[context.p0.parsed.x];

    if (currentDateString === targetDate) {
        return [5, 5];
    }

    const currentDate = fusionMoment(currentDateString);
    const projectionDate = fusionMoment(targetDate);

    if (currentDate.isAfter(projectionDate)) {
        return [5, 5];
    }

    return undefined;
};

const processDatasets = (datasets: OverviewChartData) => {
    const allData = datasets?.flatMap((dataset, idx) => {
        const convertedData = dataset.values.reduce(
            (acc, item) => {
                acc.x.push(item.x);
                acc.y.push(item.y);

                return acc;
            },
            { x: [], y: [] },
        );

        const { values } = formatChartData(convertedData, getColorForDataset(idx), dataset.label);

        return values;
    });

    allData.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());

    const uniqueDates = new Map();
    allData.forEach(item => {
        uniqueDates.set(item.x, item);
    });

    const sortedUniqueData = Array.from(uniqueDates.values()).sort(
        (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime(),
    );

    const groupedData = datasets?.map((dataset, idx) => ({
        name: dataset.label,
        color: getColorForDataset(idx),
        values: sortedUniqueData.map(item => ({
            x: item.x,
            y: dataset.values.find(v => v.x === item.x)?.y || null,
        })),
    }));

    return groupedData;
};

interface GetChartDataParams {
    chartView: string;
    chartData: ChartData;
    overviewChartData: OverviewChartData;
    dashedLineStartDate: string;
}

export const getChartData = ({
    chartView,
    chartData,
    overviewChartData,
    dashedLineStartDate,
}: GetChartDataParams) => {
    if (chartView === 'overview') {
        const data = formatChartData(chartData, colorPallet.neutral[800], 'Overview');

        return [
            {
                data: data.values,
                borderColor: data.color,
                segment: {
                    borderDash: context =>
                        createDottedLine({
                            chartData,
                            context,
                            targetDate: dashedLineStartDate,
                        }),
                },
                backgroundColor: data.color,
                tooltipBackgroundColor: data.color,
            },
        ];
    }

    // chart data for monthly view
    const formattedData = processDatasets(overviewChartData);

    return formattedData.map(data => ({
        data: data.values,
        borderColor: data.color,
        label: data.name,
        backgroundColor: data.color,
        tooltipBackgroundColor: data.color,
        spanGaps: true,
    }));
};
