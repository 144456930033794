import { memo, useState } from 'react';
import { Card, Switch } from '@mantine/core';

import { RevenueChart } from './RevenueChart';
import { Insight } from '../../useGetInsights';

export const RevenueSection = memo(({ insightData }: { insightData: Insight }) => {
    const [checked, setChecked] = useState(true);

    return (
        <Card shadow="sm" padding="0" radius="md" withBorder className="relative my-5 mr-5 md:mr-0">
            <div className="absolute right-2 top-12 z-10">
                <Switch
                    checked={checked}
                    onChange={event => setChecked(event.currentTarget.checked)}
                    label={checked ? 'Monthly' : 'Last 12 Months'}
                    labelPosition="left"
                    color="#2563eb"
                />
            </div>

            <RevenueChart
                selectedChartView={checked ? 'monthly' : 'overview'}
                chart_data={insightData?.hero_card?.chart_data}
                name={insightData?.hero_card?.title}
                title={insightData?.hero_card?.value}
                subtitle={`${insightData?.hero_card?.change} ${insightData?.hero_card?.change_unit}`}
                change={insightData?.hero_card?.change}
                value_unit={insightData?.hero_card?.value_unit}
                overview_data={insightData?.hero_card?.overview_data}
            />
        </Card>
    );
});
