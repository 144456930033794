import { useMemo } from 'react';

import { HeroBreakdown, useGetInsights } from '../../useGetInsights';

const findDriverTitleById = (data: HeroBreakdown[], driverId: number): string => {
    const foundDriver = data
        .flatMap(metric => metric.drivers)
        .find(driver => driver.id === driverId);

    return foundDriver ? foundDriver.title : '';
};

export const useSignalTitle = (signalId: number | string): string => {
    const { data: insightData, isLoading: isLoadingInsightData } = useGetInsights('YoY');

    const signalTitle = useMemo(() => {
        if (isLoadingInsightData || !insightData?.hero_breakdown) {
            return '';
        }

        return findDriverTitleById(insightData.hero_breakdown, Number(signalId));
    }, [insightData, isLoadingInsightData, signalId]);

    return signalTitle;
};
