import { useMemo, useState } from 'react';
import { Drawer, Skeleton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ArrowRight, ArrowUUpLeft, Broadcast, House } from 'phosphor-react';
import { motion } from 'framer-motion';
import isMobile from 'ismobilejs';

import { useAppContext } from '@/contexts/appContext';
import { Circle } from 'andromeda/Circle';
import colors from '@/constants/colors';
import { Header } from '../Header';
import { Driver, HeroBreakdown, Insight, useGetInsights } from '../useGetInsights';
import { RevenueSection } from './RevenueSection/RevenueSection';
import { SalesConstructCard } from './SalesConstructCard';
import { SignalCard } from './SignalCard';
import { SignalDrawer } from './SignalDrawer/SignalDrawer';
import { useUlyssesQueryParams } from '../useUlyssesQueryParams';

const constructColor = {
    'Average Bill Value': '#345DEE50',
    'Bills / Outlet': '#EBA33850',
    '# Outlets (ECO)': '#BB54B550',
};

const getTop3Drivers = (data: HeroBreakdown[]): Driver[] => {
    const allDrivers = data?.flatMap(construct =>
        construct.drivers.map(driver => ({
            ...driver,
            constructName: `${construct.metadata.title}`,
        })),
    );

    const sortedDrivers = allDrivers?.sort((a, b) => Math.abs(b.impact) - Math.abs(a.impact));
    return sortedDrivers?.slice(0, 3);
};

export const HomePage = () => {
    const { user } = useAppContext();
    const message = `Welcome, ${user?.firstName}`;

    const { signal: signalID, updateSignal } = useUlyssesQueryParams();

    const { data: insightData = {} as Insight, isLoading: isLoadingInsightData } =
        useGetInsights('YoY');

    const [hoveredConstruct, setHoveredConstruct] = useState('all');
    const [clickedConstruct, setClickedConstruct] = useState('');

    const activeConstruct = (() => {
        if (hoveredConstruct !== 'all') {
            return hoveredConstruct;
        }

        if (clickedConstruct) {
            return clickedConstruct;
        }

        return 'all';
    })();

    const [drawerOpened, { open, close }] = useDisclosure(Boolean(signalID));

    const handleDrawerClose = () => {
        updateSignal(undefined);
        close();
    };

    const handleSignalClick = (signalId: number) => {
        updateSignal(`${signalId}`);
        open();
    };

    const handleCardClick = (cardTitle: string) => {
        const newSelectedConstruct = cardTitle === clickedConstruct ? '' : cardTitle;
        setClickedConstruct(newSelectedConstruct);
    };

    const handleConstructHover = (construct: string) => {
        if (isMobile().phone) {
            return;
        }

        setHoveredConstruct(construct);
    };

    const signals = useMemo(() => {
        if (isLoadingInsightData || !insightData) {
            return [];
        }

        if (activeConstruct === 'all') {
            return getTop3Drivers(insightData.hero_breakdown);
        }

        const selectedConstruct = insightData.hero_breakdown.find(
            x => x.metadata.title === activeConstruct,
        );

        // Take the top 3 drivers by impact
        const sortedDrivers = selectedConstruct?.drivers.sort(
            (a, b) => Math.abs(b.impact) - Math.abs(a.impact),
        );
        return sortedDrivers.slice(0, 3);
    }, [insightData, isLoadingInsightData, activeConstruct]);

    return (
        <div className="ml-3 flex flex-col pb-12 lg:ml-5 lg:pb-0">
            <Header pageName="Home" message={message} icon={House} showFilters />
            <div className="mt-2 flex flex-col lg:mt-6">
                {isLoadingInsightData && (
                    <Skeleton height={450} width={`${isMobile().phone ? '95%' : '50%'}`} />
                )}
                {!isLoadingInsightData && (
                    <div className="flex flex-col gap-x-8 lg:flex-row">
                        <div className="w-full lg:w-1/2">
                            <RevenueSection insightData={insightData} />
                        </div>
                        <div className="mt-1 flex flex-col gap-y-2 pr-4 lg:mt-5 lg:gap-y-6 lg:pr-0">
                            {insightData?.hero_breakdown?.map(hero => (
                                <div key={hero?.metadata?.title}>
                                    <SalesConstructCard
                                        name={hero?.metadata?.title}
                                        title={`${hero?.metadata?.value} ${
                                            hero?.metadata?.value_unit
                                        }`}
                                        subtitle={`${hero?.metadata?.change} ${hero?.metadata?.change_unit}`}
                                        change={hero?.metadata?.change}
                                        onHover={() => handleConstructHover(hero?.metadata?.title)}
                                        onLeave={() => handleConstructHover('all')}
                                        onClick={() => handleCardClick(hero?.metadata?.title)}
                                        isActive={activeConstruct === hero?.metadata?.title}
                                        hoverColor={constructColor[activeConstruct]}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {/* signals */}
                <div className="w-full lg:max-w-max">
                    {/* header */}
                    <div className="w-full pr-4 lg:pr-0 ">
                        <div className="flex items-center justify-between">
                            <div className="mt-8 flex items-center gap-x-2">
                                <Broadcast size={16} weight="fill" />
                                <p className="text-xl">
                                    {activeConstruct !== 'all' ? 'Signals' : 'All Signals'}
                                </p>
                                {activeConstruct !== 'all' && (
                                    <div
                                        className={`bg-[ rounded-md px-2 py-1${constructColor[activeConstruct]}]`}
                                    >
                                        <p className="text-[10px]">{activeConstruct}</p>
                                    </div>
                                )}
                            </div>
                            <div className="mt-8 flex cursor-not-allowed items-center gap-x-2 rounded-full bg-white px-4 py-1">
                                <p className="text-sm">View All</p>
                                <ArrowRight size={16} />
                            </div>
                        </div>
                    </div>
                    {/* list */}
                    <div className="mt-4 flex w-full flex-col gap-x-8 gap-y-5 pr-4 lg:w-4/5 lg:flex-row lg:pr-0">
                        {signals?.length > 0 &&
                            signals?.map(signal => (
                                <motion.div
                                    layout
                                    key={signal.id}
                                    initial={{ opacity: 0.75 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <SignalCard
                                        title={signal.title}
                                        constructName={signal.constructName || activeConstruct}
                                        constructColor={
                                            constructColor[signal.constructName || activeConstruct]
                                        }
                                        description={signal.description}
                                        handleClick={() => handleSignalClick(signal.id)}
                                    />
                                </motion.div>
                            ))}
                    </div>
                </div>
            </div>
            <Drawer
                opened={drawerOpened}
                onClose={handleDrawerClose}
                size={`${isMobile().phone ? '100%' : '80%'}`}
                position="right"
                withCloseButton={false}
                styles={{
                    body: { minHeight: '100%', padding: 0 },
                    content: {
                        overflow: 'hidden !important',
                    },
                }}
            >
                <div className="min-h-screen bg-ulysses p-4 pr-0 lg:p-6">
                    {/* eslint-disable-next-line */}
                    <div
                        className="flex size-9 cursor-pointer items-center justify-center rounded-full bg-white text-black"
                        onClick={handleDrawerClose}
                    >
                        <div className="flex size-8 items-center justify-center rounded-full border border-gray-light bg-primary">
                            <ArrowUUpLeft size={18} />
                        </div>
                    </div>
                    {signalID && <SignalDrawer />}
                </div>
            </Drawer>
        </div>
    );
};
