import useApiQuery from '@/hooks/useApiQuery';
import { URANUS_API_URL } from 'buildConfig/processEnv';

export interface Insight {
    hero_card: HeroCard;
    hero_breakdown: HeroBreakdown[];
    top_changes_across_segments: TopChangesAcrossSegment;
}

export interface ChartData {
    x: string[];
    y: number[];
}

export interface OverviewData {
    revenue: {
        full_potential_forecast: {
            targetProjectionDate: string;
            targetProjectionValue: number;
            unit: string;
        };
        baseline_forecast: {
            targetProjectionDate: string;
            targetProjectionValue: number;
            unit: string;
        };
        current: {
            datasets: {
                label: string;
                values: {
                    x: string;
                    y: number;
                }[];
            }[];
        };
    };
}

interface HeroCard {
    title: string;
    value: number;
    value_unit: string;
    change: number;
    change_unit: string;
    chart_data: ChartData;
    overview_data: OverviewData;
}

export interface HeroBreakdown {
    metadata: Metadata;
    chart_data: ChartData;
    drivers: Driver[];
}

interface Metadata {
    title: string;
    value: number;
    value_unit: string;
    change: number;
    change_unit: string;
}

export interface Driver {
    title: string;
    id: number;
    description: Description[];
    change: number;
    impact: number;
    change_unit: string;
    constructName?: string;
}

export interface Description {
    value: string;
    style: 'regular' | 'bold';
}

interface TopChangesAcrossSegment {
    top_changes: Change[];
    bottom_changes: Change[];
}

interface Change {
    segment: string;
    segment_granularity: string;
    description: string;
    change: number;
    change_unit: string;
}

export const useGetInsights = (timescale?: string) => {
    const url = new URL(
        `${URANUS_API_URL}/promo-management/api/promo-management/insights/home_page`,
    );

    if (timescale) {
        url.searchParams.append('timescale', timescale);
    }

    return useApiQuery<Insight>({ url: url.toString() });
};
