import { UlyssesContextProvider } from '@/components/ulysses/context';
import { HomePage } from 'components/ulysses/HomePage';
import ResponsiveLayoutUlysses from '@/components/ulysses/ResponsiveLayoutUlysses';

const Home = () => (
    <ResponsiveLayoutUlysses title="Unilever Dashboard">
        <UlyssesContextProvider>
            <HomePage />
        </UlyssesContextProvider>
    </ResponsiveLayoutUlysses>
);

export default Home;
