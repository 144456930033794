import { useAppContext } from '@/contexts/appContext';
import { useMutation } from '@tanstack/react-query';
import { URANUS_API_URL } from 'buildConfig/processEnv';

interface Comment {
    comment: string;
    insight_id: number;
}

export const useAddComment = ({ disabled = false, onSuccess }) => {
    const { user } = useAppContext();

    const url = `${URANUS_API_URL}/promo-management/api/promo-management/insights/add_comment/`;

    return useMutation({
        mutationFn: async ({ comment, insight_id }: Comment) => {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${user?.siberiaToken}`,
                },
                body: JSON.stringify({ comment, first_name: user.firstName, insight_id }),
            });

            return response.json() as Promise<Comment>;
        },
        onSuccess: () => {
            onSuccess?.();
        },
    });
};
