import { Card } from '@mantine/core';
import { ArrowRight, Broadcast } from 'phosphor-react';

import { StyledSentence } from './StyledSentence';
import { Description } from '../useGetInsights';

interface SignalCardProps {
    title: string;
    constructName: string;
    constructColor: string;
    description: Description[];
    handleClick?: () => void;
}

export const SignalCard = ({
    title,
    constructName,
    description,
    handleClick,
    constructColor,
}: SignalCardProps) => (
    <Card
        padding="xs"
        radius="md"
        shadow="sm"
        withBorder
        className="relative h-full cursor-pointer"
        onClick={handleClick}
    >
        <div className="min-w-max lg:min-w-[380px]">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-4">
                    <div className="rounded-md bg-columbia-blue-lighter p-2">
                        <Broadcast size={16} weight="fill" />
                    </div>
                    <p className="text-base">{title}</p>
                </div>
                <div className={`rounded-md px-2 py-1 bg-[${constructColor}]`}>
                    <p className="text-[10px]">{constructName}</p>
                </div>
            </div>
            <div className="mt-2">
                <div className="max-w-[260px]">
                    {description && <StyledSentence segments={description} />}
                </div>
                <div className="absolute bottom-3 right-5 bg-white">
                    <ArrowRight size={18} />
                </div>
            </div>
        </div>
    </Card>
);
