import { useState } from 'react';
import { Avatar, Card, Divider, Textarea } from '@mantine/core';
import cx from 'classnames';

import { colorPallet } from '@/constants/colors';
import { useAppContext } from '@/contexts/appContext';
import { fusionMoment } from '@/utils/dateHelpers';
import { Button } from 'andromeda';
import { useAddComment } from '../../useAddComment';

export const Activity = ({
    comments,
    id,
    onRefetch,
}: {
    comments: any[];
    id: number;
    onRefetch: () => void;
}) => {
    const { user } = useAppContext();
    const [userComment, setUserComment] = useState('');

    const { mutate } = useAddComment({ disabled: !userComment, onSuccess: onRefetch });

    const handleAddComment = () => {
        mutate({ comment: userComment, insight_id: id });
        setUserComment('');
    };

    return (
        <div className="mt-14">
            <p className="mb-4 text-2xl font-medium">Activity</p>
            <Divider size="xs" color={colorPallet['columbia-blue-v2'][300]} />

            {/* comments */}
            {comments?.length > 0 && (
                <div className="mt-5 flex flex-col gap-y-10">
                    {comments.map((comment, index) => (
                        // TODO: change key to comment ID once we have it
                        <div
                            key={comment.created_at}
                            className={cx('relative', {
                                'mb-6': comments.length - 1 === index,
                            })}
                        >
                            <div className="flex items-start">
                                <div className="mr-4 shrink-0">
                                    <Avatar
                                        name={comment.first_name.slice(0, 1)}
                                        color="initials"
                                    />
                                </div>
                                <Card padding="0" radius="md" withBorder className="grow">
                                    {/* header */}
                                    <div className="flex h-4 items-center bg-columbia-blue-lighter p-4">
                                        <p className="text-xs font-bold">
                                            {comment.first_name}
                                            <span className="ml-1 text-xs font-normal text-neutral-400">
                                                commented on{' '}
                                                {fusionMoment(comment.date).format('MMM DD, YYYY')}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="p-4">
                                        <p className="text-sm">{comment.comment}</p>
                                    </div>
                                </Card>
                            </div>

                            <div className="absolute left-[71px] h-10 w-px self-stretch bg-columbia-blue-v2-300" />
                        </div>
                    ))}
                </div>
            )}
            <div className="mb-20 mt-4 flex flex-col">
                {comments?.length > 0 && (
                    <Divider
                        size="xs"
                        className="mb-4"
                        color={colorPallet['columbia-blue-v2'][300]}
                    />
                )}

                <div className="flex items-start">
                    <div className="mr-4 shrink-0">
                        <Avatar name={user.firstName?.slice(0, 1)} color="initials" />
                    </div>
                    <Card padding="" radius="md" className="grow">
                        <Textarea
                            placeholder="Add a comment"
                            radius="md"
                            autosize
                            minRows={4}
                            value={userComment}
                            onChange={event => setUserComment(event.currentTarget.value)}
                        />
                    </Card>
                </div>
                <div className="mt-5 max-w-max cursor-pointer self-end rounded-full px-4 py-2 font-bold">
                    <Button
                        onClick={handleAddComment}
                        variant="primary"
                        disabled={!userComment.length}
                    >
                        Send
                    </Button>
                </div>
            </div>
        </div>
    );
};
