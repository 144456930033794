import useApiQuery from '@/hooks/useApiQuery';
import { URANUS_API_URL } from 'buildConfig/processEnv';
import { ChartData, Description } from './useGetInsights';

export interface Segment {
    segment: string;
    segment_granularity: string;
    change: number;
    change_text: string;
    change_unit: string;
}

interface Insight {
    title: string;
    impact: string;
    impact_percent: string;
    change: number;
    change_unit: string;
    chart_data: ChartData;
    segments: Segment[];
    abv_impact: string;
    target_name: string;
}

interface SidebarDriverDetails {
    title: Description[];
    insights: Insight[];
    comments: any[];
}

export const useGetSidebarByDriver = (id: number) => {
    const url = new URL(
        `${URANUS_API_URL}/promo-management/api/promo-management/insights/get_sidebar_by_driver?id=${id}`,
    );

    return useApiQuery<SidebarDriverDetails>({ url: url.toString() });
};
