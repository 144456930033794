/* eslint-disable no-unsafe-optional-chaining */
import { useState } from 'react';
import Link from 'next/link';
import { Card } from '@mantine/core';
import {
    ArrowRight,
    Barcode,
    Bookmark,
    Briefcase,
    Broadcast,
    CaretDown,
    CaretUp,
    ChartBar,
    Storefront,
    Tag,
    UserCirclePlus,
} from 'phosphor-react';
import isMobile from 'ismobilejs';

import { Spinner } from 'andromeda';
import { TabValue } from 'andromeda/tab/tabWrapper';
import StyledTab from 'andromeda/tab/tabComponent';
import { colorPallet } from '@/constants/colors';
import { formatNumber } from '@/utils/numberFormatHelpers';
import { useAppContext } from '@/contexts/appContext';
import { StyledSentence } from '../StyledSentence';
import { useGetSidebarByDriver } from '../../useGetSidebarByDriver';
import { useUlyssesQueryParams } from '../../useUlyssesQueryParams';
import { useSignalTitle } from './useSignalTitle';
import { Activity } from './Activity';

// TODO: remove after the names are fixed
const formatSegmentName = (segment: string) => {
    if (segment.includes('Residential')) {
        return 'Residential / Accommodation';
    }

    return segment;
};

const iconMap = {
    Brand: Barcode,
    Cluster: Tag,
    Supervisor: Briefcase,
};

const MapTItlesToTabNames = {
    Brand: 'brand',
    Cluster: 'cluster_name',
    Supervisor: 'supervisor',
};
const GranularityIcon = ({ granularity }: { granularity: string }) => {
    const IconComponent = iconMap[granularity];
    return IconComponent ? <IconComponent size={16} /> : null;
};

type Tab = 'Overview' | 'Accounts' | 'Products';

const tabOptions: TabValue<Tab> = [
    {
        label: 'Overview',
        value: 'Overview',
        component: <div />,
        icon: <ChartBar size={16} />,
    },
    {
        label: 'Accounts',
        value: 'Accounts',
        component: <div />,
        icon: <Storefront size={16} />,
        disabled: true,
    },
    {
        label: 'Products',
        value: 'Products',
        component: <div />,
        icon: <Barcode size={16} />,
        disabled: true,
    },
];

const Tabs = () => {
    const [, setSelectedTab] = useState<Tab>(tabOptions[0].value);

    return (
        <StyledTab
            values={tabOptions}
            onChange={t => setSelectedTab(t)}
            defaultTab={tabOptions[0].value}
        />
    );
};

const InfoCard = ({ user, construct }: { user: string; construct: string }) => (
    <Card padding="24" radius="md" withBorder bg={colorPallet['columbia-blue-v2'][50]}>
        <div className="mb-8">
            <p className="mb-4 text-base font-bold">SALES CONSTRUCT</p>

            <div className="max-w-max rounded-md bg-[#E9D0EA] px-2 py-1">
                <p className="text-[10px]">{construct}</p>
            </div>
        </div>

        <div className="mb-8">
            <p className="mb-4 text-base font-bold">DRIVERS</p>

            <div className="max-w-max rounded-md bg-[#f5e4cb] px-2 py-1">
                <p className="text-[10px]">VISITS</p>
            </div>
        </div>

        <div className="mb-8">
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base font-bold">OWNERS</p>
                <div className="flex cursor-not-allowed items-center gap-x-2 rounded-full bg-white px-4 py-1">
                    <UserCirclePlus size={16} />
                    <p className="text-xs">Invite</p>
                </div>
            </div>

            <div className="flex flex-col gap-y-4">
                <div className="flex items-center gap-x-1">
                    <div className="flex size-6 items-center justify-center rounded-full bg-[#bb54b5] text-xs text-white">
                        M
                    </div>
                    <p className="text-sm">{user}</p>
                </div>
            </div>
        </div>

        <div>
            <div className="mb-8 flex items-center justify-between">
                <p className="text-base font-bold">TAGS</p>

                <div className="flex cursor-not-allowed items-center gap-x-2 rounded-full bg-white px-4 py-1">
                    <Bookmark size={16} />
                    <p className="text-xs">Add Tag</p>
                </div>
            </div>
            <div className="max-w-max rounded-full bg-[#badbe8] px-2 py-1">
                <p className="text-[10px] font-bold">SALES</p>
            </div>
        </div>
    </Card>
);

interface SegmentDetailsProps {
    segment: any;
    title: string;
    segmentGranularity: string;
    isActive: boolean;
    onHover: () => void;
    onLeave: () => void;
    signalTitle: string;
}

const SegmentDetails = ({
    segment,
    segmentGranularity,
    isActive,
    onHover,
    onLeave,
    title,
    signalTitle,
}: SegmentDetailsProps) => (
    <Card
        className="relative m-0 transition-all duration-300 ease-in-out"
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
    >
        <div className="flex min-h-8 items-center gap-x-2 overflow-auto">
            <div>
                <GranularityIcon granularity={segmentGranularity} />
            </div>
            <p className="text-xs text-black">{formatSegmentName(segment.segment)}</p>
        </div>

        <div
            className={`mt-3 max-h-0 transition-all duration-300 ease-in-out ${
                isActive ? 'max-h-20 opacity-100' : 'overflow-hidden opacity-0'
            }`}
        >
            <p className="text-xs text-columbia-blue-darker">Sales Impact (Aug &apos;24)</p>
            <p className="mt-1 text-base font-bold text-black">
                {segment.change_text} {segment.change_unit}
            </p>
        </div>
        {isActive && (
            // !
            <div className="absolute right-3 top-3">
                <Link
                    href={`/segment-analysis?tab=${MapTItlesToTabNames[segmentGranularity]}&segment=${signalTitle}&row=${JSON.stringify({ [MapTItlesToTabNames[segmentGranularity]]: segment.segment, column: title })}`}
                >
                    <ArrowRight size={16} />
                </Link>
            </div>
        )}
    </Card>
);

const MetricCard = ({
    title,
    yoyChangeValue,
    yoyChangeUnit,
    currently,
    previously,
    segments,
    salesImpact,
    salesImpactPercent,
    signalTitle,
}) => {
    const [hoveredSegment, setHoveredSegment] = useState('');

    return (
        <div className="w-full lg:max-w-max">
            <Card padding="0" radius="md" withBorder>
                <div className="p-4">
                    <p className="text-xl">{title}</p>

                    <div className="mt-6 flex justify-between pb-4 pr-5">
                        <div className="flex flex-col gap-y-1">
                            <p className="text-xs text-columbia-blue-darker">Aug 2024</p>
                            <p className="text-2xl font-bold">
                                {title.toLowerCase().includes('day')
                                    ? currently
                                    : formatNumber(currently, '0.0a')}
                            </p>
                        </div>
                        <div className="flex flex-col gap-y-1">
                            <p className="text-xs text-columbia-blue-darker">Aug 2023</p>
                            <p className="text-2xl font-bold">
                                {' '}
                                {title.toLowerCase().includes('day')
                                    ? previously
                                    : formatNumber(previously, '0.0a')}
                            </p>
                        </div>
                        <div className="flex flex-col gap-y-1">
                            <p className="text-xs text-columbia-blue-darker">YoY Change</p>
                            <p
                                className={`text-2xl font-bold ${
                                    yoyChangeValue > 0
                                        ? 'text-emerald-dark'
                                        : yoyChangeValue < 0
                                          ? 'text-coral-dark'
                                          : yoyChangeValue === 0
                                            ? 'text-columbia-blue-darker'
                                            : 'text-black'
                                }`}
                            >
                                <span className="inline-block">
                                    {yoyChangeValue > 0 ? (
                                        <CaretUp
                                            size={24}
                                            weight="fill"
                                            color={colorPallet.emerald.dark}
                                        />
                                    ) : yoyChangeValue < 0 ? (
                                        <CaretDown
                                            size={24}
                                            weight="fill"
                                            color={colorPallet.coral.dark}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </span>
                                {`${yoyChangeValue} ${yoyChangeUnit}`}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <p className="text-xs text-columbia-blue-darker">Sales Impact</p>
                        <p className="text-2xl font-bold">
                            {salesImpact} ({salesImpactPercent})
                        </p>
                    </div>
                </div>
                <div className=" bg-columbia-blue-v2-50 p-4 ">
                    <p className="text-base">Most impacted segments</p>
                    <div className="grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2">
                        {segments?.map(segment => {
                            const segmentID = `${segment.segment}-${segment.change}`;

                            return (
                                <div key={segmentID} className="w-full cursor-default">
                                    <div className="flex flex-col gap-2">
                                        <p className="text-xs text-columbia-blue-darker">
                                            {segment.segment_granularity.toUpperCase()}
                                        </p>
                                        <SegmentDetails
                                            segment={segment}
                                            segmentGranularity={segment.segment_granularity}
                                            onHover={() => setHoveredSegment(segmentID)}
                                            onLeave={() => setHoveredSegment('')}
                                            isActive={hoveredSegment === segmentID}
                                            title={title}
                                            signalTitle={signalTitle}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export const SignalDrawer = () => {
    const { signal } = useUlyssesQueryParams();
    const { data, isLoading, refetch } = useGetSidebarByDriver(Number(signal));
    const { user } = useAppContext();

    const signalTitle = useSignalTitle(signal);

    const mobile = isMobile().phone;

    if (isLoading) {
        return <Spinner size={28} />;
    }

    return (
        <div className="w-full px-4 pl-10 lg:ml-[72px] lg:w-auto lg:px-0">
            {/* header */}
            <div>
                <div className="flex items-center gap-x-4">
                    <Broadcast size={48} />
                    <p className="mr-1 text-[24px] font-semibold text-secondary">{signalTitle}</p>
                </div>
                <div className="mt-2 flex items-center">
                    <div className="flex max-w-max items-center gap-x-1 rounded-md bg-columbia-blue-light px-2">
                        <Broadcast size={14} color={colorPallet.pacific.default} />
                        <p className="text-sm font-medium text-pacific">ACTIVE</p>
                    </div>
                    <div className="mx-2 flex gap-x-1 text-sm ">
                        <p className="text-navy-50">From:</p>
                        <p>21 hours ago</p>
                    </div>
                    <p>-</p>
                    <div className="ml-2 flex gap-x-1 text-sm">
                        <p className="text-navy-50">Until:</p>
                        <p>Now</p>
                    </div>
                </div>
            </div>
            <div className="mt-12 flex w-full lg:gap-x-7">
                <div className="w-4/5">
                    {/* tabs */}
                    <Tabs />
                    {/* summary */}
                    <div className="mt-8 lg:mt-12">
                        <p className="mb-4 text-2xl font-medium">Summary</p>
                        {data?.title && <StyledSentence segments={data?.title} />}
                    </div>
                    {/* metrics */}
                    <div className="mt-8 lg:mt-12">
                        <p className="mb-4 text-2xl font-medium">Performance metrics</p>
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                            {data?.insights?.map(insight => (
                                <MetricCard
                                    key={insight?.title}
                                    title={insight?.title}
                                    yoyChangeValue={insight?.change}
                                    yoyChangeUnit={insight?.change_unit}
                                    currently={
                                        insight?.chart_data?.y[
                                            (insight?.chart_data?.y?.length || 0) - 1
                                        ]
                                    }
                                    previously={
                                        insight?.chart_data?.y[
                                            (insight?.chart_data?.y?.length || 0) - 1
                                        ] - insight?.change
                                    }
                                    segments={insight?.segments}
                                    salesImpact={insight?.impact}
                                    salesImpactPercent={insight?.impact_percent}
                                    signalTitle={signalTitle}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                {!mobile && (
                    <InfoCard user={user?.firstName} construct={data?.insights[0]?.target_name} />
                )}
            </div>
            <Activity comments={data?.comments} id={Number(signal)} onRefetch={refetch} />
        </div>
    );
};
