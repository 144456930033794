import { Divider } from '@mantine/core';
import { CaretDown, CaretUp } from 'phosphor-react';
import { TooltipLabelStyle } from 'chart.js';
import isMobile from 'ismobilejs';
import cx from 'classnames';
import { LineChart, Text } from 'andromeda';
import { colorPallet } from '@/constants/colors';
import { formatNumber } from '@/utils/numberFormatHelpers';
import { fusionMoment } from '@/utils/dateHelpers';
import StyledTooltip from '@/components/utils/styledTooltip';
import { ChartData, OverviewData } from '../../useGetInsights';
import { getChartData, RevenueChartDatasetType } from './chartUtils';
import { driverBreakdown } from '../../SegmentsAnalysisPage/driverBreakdown';

interface RevenueChartProps {
    name: string;
    title: string | number;
    subtitle: string;
    chart_data?: ChartData;
    change: number;
    value_unit: string;
    overview_data?: OverviewData;
    selectedChartView: string;
}

export const RevenueChart = ({
    name,
    title,
    subtitle = '',
    chart_data,
    change,
    value_unit = '',
    overview_data,
    selectedChartView,
}: RevenueChartProps) => {
    const dashedLineStartDate = overview_data?.revenue?.baseline_forecast?.targetProjectionDate;
    const chartData = getChartData({
        chartView: selectedChartView,
        chartData: chart_data,
        overviewChartData: overview_data?.revenue?.current?.datasets,
        dashedLineStartDate,
    });

    const allDates = [
        ...new Set(chartData.flatMap(dataset => dataset.data.map(point => point.x))),
    ].sort();

    const mobile = isMobile().phone;

    return (
        <div className="flex flex-col p-4">
            <div className="flex w-full flex-col">
                <div className="flex items-center gap-20">
                    <StyledTooltip
                        label={driverBreakdown['Sales Performance']?.['Sales Growth (YoY)']}
                    >
                        <div className="text-xl font-medium !text-neutral-300">{name}</div>
                    </StyledTooltip>
                </div>
                <div className="mt-2 flex gap-x-8">
                    <div>
                        <p className="text-sm font-medium">Total Last Week</p>
                        <p className="text-xs text-columbia-blue-darker">Aug 1 &rarr; 31, 2024</p>
                    </div>
                    <div>
                        <p className="text-sm font-medium">Change YoY</p>
                        <p className="text-xs text-columbia-blue-darker">Aug 1 &rarr; 31, 2023</p>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="mr-4 pt-4">
                        <div className="text-3xl font-bold !text-neutral-300">
                            {title} {value_unit}
                        </div>
                    </div>

                    <div className="min-h-[32px] pt-4">
                        <div
                            className={cx(
                                'ml-2 flex items-center justify-center rounded-full px-2 py-1',
                                {
                                    'bg-[#dff4e5]': change > 0,
                                    'bg-[#f3e1e2]': change < 0,
                                },
                            )}
                        >
                            {change > 0 ? (
                                <CaretUp size={20} color={colorPallet.emerald.dark} weight="fill" />
                            ) : (
                                <CaretDown size={20} color={colorPallet.coral.dark} weight="fill" />
                            )}

                            <div className="flex items-center gap-x-2">
                                <Text
                                    type="16Reg"
                                    className={`!font-bold ${change > 0 ? 'text-emerald-dark' : 'text-coral-dark'}`}
                                >
                                    {subtitle}
                                </Text>
                                <Divider
                                    orientation="vertical"
                                    size="sm"
                                    color={`!font-bold ${change > 0 ? 'text-emerald-dark' : 'text-coral-dark'}`}
                                />
                                <Text
                                    type="16Reg"
                                    className={`!font-bold ${change > 0 ? 'text-emerald-dark' : 'text-coral-dark'}`}
                                >
                                    +7.6M ฿
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h-[300px] w-full self-start">
                <LineChart
                    data={{
                        datasets: chartData,
                    }}
                    legend={{
                        display: selectedChartView !== 'overview',
                        position: 'bottom',
                    }}
                    yAxis={{
                        display: false,
                    }}
                    xAxis={{
                        ticks: {
                            callback(idx, tickIndex) {
                                if ((idx as number) >= allDates.length) {
                                    return '';
                                }

                                const date = fusionMoment(allDates[idx]);

                                if (selectedChartView === 'overview') {
                                    return tickIndex % (mobile ? 10 : 7) === 0
                                        ? date.format('MMM YY')
                                        : '';
                                }

                                if (
                                    tickIndex % 5 === 0 ||
                                    date.date() === 1 ||
                                    idx === allDates.length - 1
                                ) {
                                    return date.format('MMM DD');
                                }

                                return '';
                            },
                            maxRotation: 0,
                            align: 'inner',
                            autoSkip: false,
                        },
                    }}
                    tooltip={{
                        mode: 'index',
                        callbacks: {
                            labelColor(this, tooltipItem) {
                                const dataset = tooltipItem.dataset as RevenueChartDatasetType;

                                return {
                                    backgroundColor: dataset.tooltipBackgroundColor,
                                    borderColor: dataset.borderColor,
                                    borderDash: dataset.borderDash,
                                    borderDashOffset: dataset.borderDashOffset,
                                    borderWidth: 4,
                                    borderRadius: 1,
                                } as TooltipLabelStyle;
                            },
                            label: ({ dataset, parsed }) => {
                                if (!dataset || parsed.y === null) {
                                    return '';
                                }

                                const formattedValue = formatNumber(parsed.y, '0.0a');

                                return dataset.label
                                    ? `${dataset.label}: ${formattedValue}`
                                    : formattedValue;
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};
