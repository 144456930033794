import { useCallback } from 'react';
import useRouterQuery from '../../hooks/useRouterQuery';

export interface UlyssesQueryParams {
    clusterId?: string;
    accountID?: string;
    routeID?: string;
    supervisor?: string;
    brand?: string;
    category?: string;
    signal?: string;
}

export const useUlyssesQueryParams = () => {
    const { router, handleURLParamsChange } = useRouterQuery<keyof UlyssesQueryParams>();

    const query = router.query as unknown as UlyssesQueryParams;

    const updateclusterId = useCallback(
        (id?: string) => {
            handleURLParamsChange({ key: 'clusterId', value: id });
        },
        [handleURLParamsChange],
    );

    const updateaccountID = useCallback(
        (id?: string) => {
            handleURLParamsChange({ key: 'accountID', value: id });
        },
        [handleURLParamsChange],
    );

    const updaterouteID = useCallback(
        (id?: string) => {
            handleURLParamsChange({ key: 'routeID', value: id });
        },
        [handleURLParamsChange],
    );

    const updateSupervisor = useCallback(
        (id?: string) => {
            handleURLParamsChange({ key: 'supervisor', value: id });
        },
        [handleURLParamsChange],
    );

    const updateBrand = useCallback(
        (id?: string) => {
            handleURLParamsChange({ key: 'brand', value: id });
        },
        [handleURLParamsChange],
    );

    const updateCategory = useCallback(
        (id?: string) => {
            handleURLParamsChange({ key: 'category', value: id });
        },
        [handleURLParamsChange],
    );

    const updateSignal = useCallback(
        (id?: string) => {
            handleURLParamsChange({ key: 'signal', value: id });
        },
        [handleURLParamsChange],
    );

    return {
        ...query,
        updateclusterId,
        updateaccountID,
        updaterouteID,
        updateSupervisor,
        updateBrand,
        updateCategory,
        updateSignal,
        handleURLParamsChange,
    };
};
